<template>
  <div class="v-stack h-stretch gap-3">
    <div class="overlay" ref="overlay">
      <div class="card dynamic overlay-card v-start scroll-y">
        <div class="content h-stretch v-start">
          <div class="v-stack h-stretch gap-3">
            <template v-if="recentProjects.length > 0">
              <div class="h-stack h-space-between">
                <a class="text-left">Recent</a>
                <i class="fas fa-times link" @click="dismissOverlay()"></i>
              </div>
              <Table :items="recentProjects" :defaultColumn="0" v-on:rowSelected="setProject($event)" :css="'1fr 80px'"
                :showCount="false" :columns="[
                  {
                    name: 'Project',
                    sort: 'alphabet',
                    path: ['name'],
                  },
                ]"></Table>
            </template>
            <div v-bind:class="[isMobile() ? 'v-stack gap-3 h-stretch' : 'h-stack pane-right gap-3']">
              <div v-bind:class="[isMobile() ? 'v-stack gap-3 h-stretch' : 'pane-left gap-3']">
                <a class="text-left">Projects</a>
                <input type="text" placeholder="Type to search..." v-model="search" />
              </div>
              <div v-bind:class="[isMobile() ? 'v-stack gap-3 h-stretch' : 'h-stack gap-3']">
                <label style="width: 40px">Client</label>
                <select class="fixed" v-model="selectedClient">
                  <option v-for="client in   clients" :key="client">
                    {{ client }}
                  </option>
                </select>
                <label style="width: 40px">State</label>
                <select class="fixed" v-model="selectedState">
                  <option v-for="state in   states" :key="state" :value="state">
                    {{ state.charAt(0).toUpperCase() + state.slice(1) }}
                  </option>
                </select>
              </div>
            </div>
            <Table :items="filteredProjects" :defaultColumn="0" v-on:rowSelected="setProject($event)" :css="'1fr 120px'"
              :columns="[
                {
                  name: 'Project',
                  sort: 'alphabet',
                  path: ['name'],
                },
                {
                  name: 'State',
                  sort: 'alphabet',
                  path: ['state'],
                },
              ]"></Table>
          </div>
        </div>
      </div>
    </div>

    <a class="heading-title-1">Info</a>
    <div class="card light mn">
      <div class="content h-stretch">
        <form class="h-stretch gap-3">
          <div class="pane-left gap-3">
            <label class="text-left ma">Date</label>
            <InputDatePicker v-model="date"></InputDatePicker>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="pane-left gap-3">
              <label class="text-left ma">Start</label>
              <datetime :auto="true" type="time" :minute-step="15" v-model="start"></datetime>
            </div>
            <div class="pane-left gap-3">
              <label class="text-left ma">End</label>
              <datetime type="time" :auto="true" :minute-step="15" v-model="end"></datetime>
            </div>
          </div>
          <label>{{ totalHours }} h</label>
        </form>
      </div>
    </div>
    <a class="heading-title-1">Hours</a>
    <div class="h-stack h-end">
      <button class="add" @click="addWorkHour">Add</button>
    </div>
    <div class="table">
      <div class="table-header workhour-row">
        <div class="text-left">Project</div>
        <div class="text-left">Hours</div>
        <div class="text-left">Activity</div>
        <div class="text-left">Comment</div>
        <div class="text-left">Cancel</div>
      </div>
      <div class="table-row workhour-row" v-for="workhour in   workhours" :key="workhour.id">
        <div class="h-stack h-start gap-3">
          <button class="list-button" @click="selectWorkhour(workhour)">
            <i class="fas fa-list fa-lg" style="align-self: center"></i>
          </button>
          <label>{{ workhour.projectName || "Pick a project" }}</label>
        </div>
        <input type="number" v-model="workhour.hours" />
        <select v-model="workhour.activity">
          <option v-for="(activity, index) in   activities" :key="index">
            {{ activity }}
          </option>
        </select>
        <input type="text" v-model="workhour.comment" />
        <button class="error cancel-button" @click="removeWorkHour(workhour.id)">
          X
        </button>
      </div>
      <div v-if="workhours.length == 0" class="table-row">
        <div>No hours</div>
      </div>
    </div>
    <label>{{ filledHours }} / {{ totalHours }} h</label>
    <div class="h-stack h-end">
      <button class="submit" @click="submit()">Submit</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import Table from "@/components/Table.vue";
import constants from "@/constants.js";
import Fuse from "fuse.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      date: moment().toISOString(),
      start: moment().toISOString(),
      end: moment().toISOString(),
      workhours: [],
      projects: [],
      clients: [],
      activities: [],
      states: ["all"].concat(constants.projectState),
      selectedWorkhour: null,
      selectedState: "all",
      selectedClient: "All",
      user: null,
      search: "",
    };
  },
  components: {
    Table,
    InputDatePicker,
  },
  computed: {
    recentProjects() {
      if (this.user) {
        let workhours = [];
        for (const workday of this.user.workdays) {
          workhours = workhours.concat(workday.workhours);
        }

        let projects = Array.from(
          new Set(workhours.map((item) => item.project))
        );

        projects = projects.slice(-3);

        projects = this.projects.filter((item) => {
          for (const id of projects) {
            if (id == item._id) {
              return true;
            }
          }
          return false;
        });

        return projects;
      }

      return [];
    },
    filteredProjects() {
      let projects = this.projects.slice();

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["name"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(projects, options);
        return fuse.search(query).map((resultItem) => resultItem.item);
      }

      if (this.selectedState != "all") {
        projects = projects.filter((item) => item.state == this.selectedState);
      }

      if (this.selectedClient != "All") {
        projects = projects.filter(
          (item) => item.client.name == this.selectedClient
        );
      }

      return projects;
    },
    totalHours() {
      const start = moment(this.day)
        .hour(moment(this.start).hour())
        .minute(moment(this.start).minute())
        .second(0);
      let end = moment(this.day)
        .hour(moment(this.end).hour())
        .minute(moment(this.end).minute())
        .second(0);

      if (end.isBefore(start)) {
        end = end.add(1, "d");
      }

      const difference = end.diff(start, "hours", true);

      return Math.round(difference * 100) / 100;
    },
    filledHours() {
      let hours = 0;
      for (const workhour of this.workhours) {
        hours += Number(workhour.hours);
      }
      return hours;
    },
  },
  methods: {
    ...mapActions([
      "getProjects",
      "addWorkday",
      "getPricelistItems",
      "getUser",
    ]),
    isMobile() {
      return this.$isMobile()
    },
    addWorkHour() {
      this.workhours.push({
        id: uuidv4(),
        project: "",
        activity: "",
        comment: "",
        hours: 0,
        projectName: "",
      });
    },
    removeWorkHour(id) {
      const index = this.workhours
        .map((element) => {
          return element.id;
        })
        .indexOf(id);
      this.workhours.splice(index, 1);
    },
    submit() {
      if (this.totalHours == this.filledHours && this.totalHours > 0) {
        this.addWorkday({
          day: this.date,
          start: this.start,
          end: this.end,
          workhours: this.workhours,
          user: this.id,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    selectWorkhour(workhour) {
      this.selectedWorkhour = workhour;
      this.$refs.overlay.classList.add("show");

      if (this.$isMobile()) {
        document.body.classList.add("no-scroll")
      }
    },
    setProject(project) {
      this.selectedWorkhour.project = project._id;
      this.selectedWorkhour.projectName = project.name;
      this.$refs.overlay.classList.remove("show");

      document.body.classList.remove("no-scroll")
    },
    dismissOverlay() {
      this.$refs.overlay.classList.remove("show");

      document.body.classList.remove("no-scroll")
    }
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("no-scroll")
    next();
  },
  mounted() {
    this.getProjects()
      .then((projects) => {
        this.projects = projects;

        this.clients = Array.from(
          new Set(projects.map((item) => item.client.name))
        );

        this.clients = ["All"].concat(
          this.clients.sort((a, b) => a.localeCompare(b, "cs"))
        );
      })
      .catch((error) => {
        console.log(error);
      });

    this.getUser(this.id)
      .then((user) => {
        this.user = user;

        this.activities = user.categories
          .map((item) => item.name)
          .sort((a, b) => a.localeCompare(b, "cs"));
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.workhour-row {
  grid-auto-columns: 1.5fr 80px 160px 1fr 40px !important;
  gap: 10px;
}

.cancel-button {
  text-align: center;
  padding: 0px;
  width: 41px !important;
  height: 41px !important;
  box-shadow: none;
  line-height: 0px;
  margin: 0;
  align-self: center;
}

.list-button {
  background-color: white;
  width: 41px !important;
  height: 41px !important;
  box-shadow: none;
  line-height: 0px;
  align-self: center;
  margin: 5px;
  border: 1px solid #ccc;
}

.overlay-card {
  top: 30px;
  position: absolute;
  height: fit-content;
  max-height: calc(100vh - 90px);
  width: 80vw;
  max-width: 952px;
}

.mobile .overlay-card {
  top: 0px;
  height: 100%;
  max-height: unset;
  width: 100%;
  max-width: unset;
  position: static;
  margin: 0px
}

a,
label,
select,
input {
  align-self: center;
}

select.fixed {
  width: 160px;
}

.mobile select.fixed {
  width: 100%
}

.link {
  user-select: none;
  cursor: pointer;
}
</style>

<style lang="scss">
.overlay-card .table-footer {
  padding-bottom: 16px !important;
}

.no-scroll {
  overflow: hidden;
}
</style>
